export default {
  sendCode,
  verifyCode,
  parseHash,
  getUserInfo
};

async function fetchFromServer(path, body) {
  const response = await fetch(process.env.REACT_APP_AUTH_DOMAIN + path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
  return await response.json();
}
function sendCode(email) {
  return new Promise(async (resolve, reject) => {
    const result = await fetchFromServer('/sendCode', {
      email: email
    })
    if(result.status == 'OK')
      resolve(result.status);
    else
      reject(result.status);
  });
}

function verifyCode(email, verificationCode) {
  return new Promise(async (resolve, reject) => {
    const result = await fetchFromServer('/verifyCode', {
      email: email,
      code: verificationCode
    });

    if(result.status == 'OK'){
      resolve(result.status);
      window.location.hash = result.hash;
    }
    else
      reject(result.status);
  });
}

function parseHash() {
  return new Promise((resolve, reject) => {
      resolve(window.location.hash.substring(1));
  });
}

// await fetch("https://a.szollosimail.com/sendCode", {
//     "credentials": "omit",
//     "headers": {
//         "User-Agent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:127.0) Gecko/20100101 Firefox/127.0",
//         "Accept": "*/*",
//         "Accept-Language":  "en-GB,en;q=0.5",
//         "Content-Type": "application/json",
//         "Sec-GPC": "1",
//         "Sec-Fetch-Dest": "empty",
//         "Sec-Fetch-Mode": "cors",
//         "Sec-Fetch-Site": "same-site"
//     },
//     "body": "{email: 'it@szollosimail.com'}",
//     "referrer": "https://mt.szollosimail.com/",
//     "method": "POST",
//     "mode": "cors"
// });

function getUserInfo(accessToken) {
  return new Promise(async (resolve, reject) => {
    const result = await fetchFromServer('/userinfo', {
      hash: accessToken
    });
    if(result.result == 'OK')
      resolve({ couchDB: result.couchDB });
    else
      reject(result.result);
  });
}
